import { Box, Link } from "flicket-ui";
import { ContainedImage } from "~components/common/Image";
import { ExtendedFile } from "~graphql/sdk";
import { flicketLogo } from "~lib/helpers/constants";

export const MobileLogo = ({ logo }: { logo: ExtendedFile }) => {
  return (
    <Box d={{ _: "flex", md: "none" }} justifyContent="center" pt={3} mb={3}>
      <Link to="/" alignSelf="center" mt={{ _: 0, md: 3 }}>
        <ContainedImage
          image={logo}
          fallback={flicketLogo}
          alt="logo"
          priority={true}
          width={80}
          height={30}
          position="center"
        />
      </Link>
    </Box>
  );
};
